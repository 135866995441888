
import { mapMutations } from "vuex";

export default {
  name: "SectionConnexion",
  methods: {
    register() {
      this.$router.push("/mon-compte/creer-mon-compte");
    },
    goToFavorites() {
      this.$router.push('/mes-favoris')
    },
    btnClick() {
      if(this.$auth.isAuthenticated){
        this.goToFavorites();
      } else {
        this.showModal({componentName:'UserModal'})
      }
    },
    ...mapMutations({
        showModal: 'modal/showModal',
      })
  },
};
